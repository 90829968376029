import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { useToast } from '@/utils/atoms/toast';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  ButtonProps,
  Center,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from '@remix-run/react';
import { lazy, useCallback, useState } from 'react';
import { MdQrCodeScanner } from 'react-icons/md';
import { useAssetByQRcodeQuery } from '../graphql/assets.generated';
import { IAsset } from '../types';
import { formatAssetName } from '../utils';
const QrScanner = lazy(() => import('@/common/components/QrScanner'));

type ModalActionType = 'detail' | 'createWorkOrder' | 'createRequest';
type QrScannerOptionModalActionType = {
  props: ButtonProps;
  textKey: string;
  type: ModalActionType;
};

type QrScannerModalProps = {
  workOrderCreateButtonClicked?: (assetId?: number) => void;
};

const optionChildren: QrScannerOptionModalActionType[] = [
  {
    props: {
      colorScheme: 'primary',
      width: { base: '80%', md: '300px' },
      mb: '4',
    },
    textKey: 'actions.create-task',
    type: 'createWorkOrder',
  },
  {
    props: {
      colorScheme: 'primary',
      width: { base: '80%', md: '300px' },
      mb: '4',
    },
    textKey: 'actions.create-request',
    type: 'createRequest',
  },
  {
    props: {
      colorScheme: 'gray',
      variant: 'outline',
      width: { base: '80%', md: '300px' },
    },
    textKey: 'actions.asset-info',
    type: 'detail',
  },
];

const QrScannerModal = ({ workOrderCreateButtonClicked }: QrScannerModalProps) => {
  const { isOpen: isQrModalOpen, onOpen: onQrModalOpen, onClose: onQrModalClose } = useDisclosure();
  const {
    isOpen: isErrorModalOpen,
    onOpen: onErrorModalOpen,
    onClose: onErrorModalClose,
  } = useDisclosure();
  const {
    isOpen: isOptionModalOpen,
    onOpen: onOptionModalOpen,
    onClose: onOptionModalClose,
  } = useDisclosure();

  const { toast } = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isMobile } = useScreenInfos();

  const { t, t_errors, t_toasts } = useTranslation();

  // NOTE: For some reason useLazyQuery doesn't work as expected. await response not returned always awaiting and sending same request 2 times not working
  const { refetch: getAssetByQRcode } = useAssetByQRcodeQuery({
    skip: true,
  });

  const [shouldPauseScan, setPauseScan] = useState<boolean>(false);
  const [asset, setAsset] = useState<IAsset | null>(null);

  const onQrModalCloseHandler = () => {
    setPauseScan(false);
    onQrModalClose();
  };

  const onErrorModalCloseHandler = () => {
    setPauseScan(false);
    onErrorModalClose();
  };

  const onOptionModalCloseHandler = () => {
    setPauseScan(false);
    onOptionModalClose();
  };

  const oScanResult = useCallback(
    async (result: string) => {
      setPauseScan(true);
      let barcode = result;
      if (result.includes(`${window.location.origin}/qr-barcode/assets/`))
        barcode = result.replace(`${window.location.origin}/qr-barcode/assets/`, '');

      setLoading(true);
      try {
        const { data } = await getAssetByQRcode({ qrcode: barcode });
        if (data) {
          setAsset(data.assetByQRcode as IAsset);
          onOptionModalOpen();
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          if (
            error.graphQLErrors.some(
              (onAssetFetchError) => onAssetFetchError.extensions?.code === '404'
            )
          ) {
            onErrorModalOpen();
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      } finally {
        setLoading(false);
      }
    },
    [onErrorModalOpen, getAssetByQRcode, onOptionModalOpen]
  );

  const onScanFail = (messageKey: string) => {
    toast({
      title: t_toasts(messageKey),
      status: 'error',
    });
    onQrModalCloseHandler();
  };

  const onOptionClicked = (type: string) => {
    onOptionModalCloseHandler();
    setPauseScan(false);
    if (asset) movePageByAssetAndType(asset?.id, type);
  };

  const movePageByAssetAndType = (assetId: number, type: string) => {
    onQrModalCloseHandler();
    onOptionModalCloseHandler();
    switch (type) {
      case 'createWorkOrder':
        if (workOrderCreateButtonClicked) {
          workOrderCreateButtonClicked(assetId);
          return;
        }
        navigate(`/?newWorkOrderAssetId=${assetId}#newWorkOrder`);
        break;
      case 'createRequest':
        navigate(`/requests/new?newRequestAssetId=${assetId}`);
        break;
      case 'detail':
        navigate(`/assets#${assetId}`);
        break;
      default:
        throw new Error('Invalid type');
    }
  };

  return (
    <>
      <IconButton
        size='sm'
        variant='ghost'
        fontSize='2xl'
        aria-label='Scan asset qr code'
        onClick={onQrModalOpen}
        icon={<MdQrCodeScanner />}
      />
      <Modal
        isOpen={isQrModalOpen}
        onClose={onQrModalCloseHandler}
        size={{ base: 'full', md: 'xl' }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton zIndex={100} size='lg' color='neutral.0' />
          <ModalBody p={0}>
            <SuspenseWithSpinner>
              <QrScanner
                qrbox={250}
                onSuccess={oScanResult}
                onFail={onScanFail}
                shouldPauseScan={shouldPauseScan}
                aspectRatio={
                  isMobile
                    ? window.innerHeight / window.innerWidth
                    : window.innerWidth / window.innerHeight
                }
              />
            </SuspenseWithSpinner>
            {isLoading ? (
              <Box
                pos='absolute'
                top='0'
                left='0'
                right='0'
                bottom='0'
                bg='blackAlpha.600'
                borderRadius='md'
                zIndex='3'
              >
                <Center height='100%'>
                  <Spinner size='xl' thickness='4px' color='white'></Spinner>
                </Center>
              </Box>
            ) : (
              <></>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isErrorModalOpen}
        onClose={onErrorModalCloseHandler}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p='6'>
            <Flex direction='column' alignItems='center'>
              <Text mb='3'>{t_errors('asset-does-not-exist')}</Text>
              <Button colorScheme='gray' onClick={onErrorModalCloseHandler}>
                {t('actions.re-scan')}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOptionModalOpen}
        onClose={onOptionModalCloseHandler}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
        size={{ base: 'full', md: 'xl' }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize='md' px={4} py={3}>
            {t('scanned-asset')}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody p='6'>
            <Flex direction='column' alignItems='center' py='3'>
              {asset ? (
                <>
                  <Text mb='3'>{asset ? formatAssetName(asset) : ''}</Text>
                  <Box my='3'></Box>
                  {optionChildren?.map((btn) => {
                    return (
                      <Button
                        key={btn.type}
                        {...btn.props}
                        onClick={() => onOptionClicked(btn.type)}
                      >
                        {t(btn.textKey)}
                      </Button>
                    );
                  })}
                </>
              ) : (
                <Text>{t_errors('asset-does-not-exist')}</Text>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='gray' onClick={onOptionModalCloseHandler}>
              {t('actions.re-scan')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default QrScannerModal;
