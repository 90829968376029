import ListEmptyText from '@/common/components/ListEmptyText';
import ListPagination from '@/common/components/ListPagination';
import { IWorkOrder } from '@/modules/workOrders/types/workOrder';
import { Box } from '@chakra-ui/react';
import { forwardRef } from 'react';
import WorkOrderCard from './WorkOrderCard';
import { WorkOrderActionsType } from './WorkOrderCardListTabs';

export type WorkOrderCardListProps = {
  workOrders: IWorkOrder[];
  targetWorkOrderId?: number;
  page?: number;
  pageLimit?: number;
  workOrdersTotalLength?: number;
  listEmptyText?: string;
  workOrderActions: WorkOrderActionsType;
  onWorkOrderClicked: (id: number) => void;
  handleLoadMore?: () => Promise<void>;
  hasManagePermission?: boolean;
};

const WorkOrderCardList = forwardRef<HTMLDivElement, WorkOrderCardListProps>(
  (props: WorkOrderCardListProps, ref) => {
    const {
      targetWorkOrderId,
      workOrders = [],
      page,
      pageLimit,
      workOrdersTotalLength,
      listEmptyText = '',
      onWorkOrderClicked,
      handleLoadMore,
      workOrderActions,
      hasManagePermission,
    } = props;

    const showPagination = page && workOrdersTotalLength && pageLimit && handleLoadMore;

    if (workOrders.length === 0) {
      return <ListEmptyText text={listEmptyText} />;
    }

    return (
      <Box bg='white' pb={5}>
        {workOrders.map((workOrder) => (
          <WorkOrderCard
            key={workOrder.id}
            workOrder={workOrder}
            isActive={workOrder.id === targetWorkOrderId}
            onWorkOrderClicked={onWorkOrderClicked}
            workOrderActions={workOrderActions}
            hasManagePermission={hasManagePermission}
            ref={workOrder.id === targetWorkOrderId ? ref : undefined}
          />
        ))}
        {!!showPagination && (
          <ListPagination
            currentPage={page}
            perPageLimit={pageLimit}
            currentItemsLength={workOrders.length}
            itemsTotalLength={workOrdersTotalLength}
            handleLoadMore={handleLoadMore}
          />
        )}
      </Box>
    );
  }
);

export default WorkOrderCardList;
